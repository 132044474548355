<template>
  <a-row>
    <div>
      <a-modal v-model:open="open" centered title="新增报备商品" :confirm-loading="confirmLoading" @ok="handleOk"
        :maskClosable="false" ok-text="确认" cancel-text="取消" @cancel="handleCancel">
        <a-form ref="formRef" :label-col="labelCol" :wrapper-col="wrapperCol" layout="horizontal"
          :disabled="componentDisabled" style="max-width: 600px">
          <a-form-item label="商品ID" name="goods_id" >
            <a-input v-model:value="formState.goods_id" />
          </a-form-item>
          <a-form-item label="推广时间段" >
            <a-range-picker v-model:value="rangePickerValue" :disabled-date="disabledDate" @change="onChange"
              @openChange="onOpenChange" @calendarChange="onCalendarChange" :locale="locale" allowClear />
          </a-form-item>

          <a-form-item label="营业执照" >
            <a-upload :before-upload="handleBeforeUpload('license')" :custom-request="customUploadRequest"
              list-type="picture-card" :max-count="2" :file-list="fileListLicense"
              :on-remove="file => handleRemove('license', file)" @preview="handlePreview">
              <div v-if="fileListLicense.length < 2">
                <PlusOutlined />
                <div style="margin-top: 8px">上传</div>
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item label="视频素材" >
            <a-upload :before-upload="handleBeforeUpload('video')" :custom-request="customUploadRequest"
              list-type="picture-card" :max-count="1" :file-list="fileListVideo"
              :on-remove="file => handleRemove('video', file)" @preview="handlePreview">
              <div v-if="fileListVideo.length < 1">
                <PlusOutlined />
                <div style="margin-top: 8px">上传</div>
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item label="预览码" >
            <a-upload :before-upload="handleBeforeUpload('previewCode')" :custom-request="customUploadRequest"
              list-type="picture-card" :max-count="1" :file-list="fileListPreviewCode"
              :on-remove="file => handleRemove('previewCode', file)" @preview="handlePreview">
              <div v-if="fileListPreviewCode.length < 1">
                <PlusOutlined />
                <div style="margin-top: 8px">上传</div>
              </div>
            </a-upload>
          </a-form-item>
        </a-form>

      </a-modal>
      <a-modal v-model:visible="previewVisible" :title="previewTitle" :maskClosable="false" :footer="null">
        <template v-if="selectedFileType === 'video'">
          <video :src="previewUrl" controls centered style="width: 70%;"></video>
        </template>
        <template v-else>
          <img :src="previewUrl" centered style="width: 70%;" />
        </template>
      </a-modal>


    </div>
    <a-row>
      <a-col :xs="6" :sm="6" :md="6" :lg="8" :xl="6">
        <a-button type="primary" @click="showModal">新增备案</a-button>
      </a-col>
      <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="18">
        <a-input-group compact>
          <a-select v-model:value="tradetext">
            <a-select-option value="Option1">商品ID</a-select-option>
            <a-select-option value="Option2">店铺ID</a-select-option>
          </a-select>
          <a-input v-model:value="formState.dataID" style="width: 45%" />
          <a-button type="primary" @click="onSubmit" :loading="isLoading">查询</a-button>
        </a-input-group>
      </a-col>
    </a-row>


    <a-col :span="24">
      <div class="table-container">
        <a-table :columns="columns" :dataSource="searchResults" @resizeColumn="handleResizeColumn" :pagination="true"
          row-key="tradeId"></a-table>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { reactive, ref, defineComponent } from 'vue';
import axios from 'axios';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
import moment from 'moment';
import { message } from 'ant-design-vue';
import { PlusOutlined } from '@ant-design/icons-vue';


export default defineComponent({
  name: 'PddPromotion',
  setup() {
    const tradetext = ref('Option1');
    const isLoading = ref(false);
    const searchResults = ref([]);
    const fileListVideo = ref([]);
    const fileListLicense = ref([]);
    const fileListPreviewCode = ref([]);
    const loading = ref(false);
    const previewVisible = ref(false);
    const previewUrl = ref('');
    const previewTitle = ref('');
    const rangePickerValue = ref([]);
    const formState = reactive({
      dataID: '',
      promotionStartDate: null, // 推广开始时间
      promotionEndDate: null,   // 推广结束时间
      mallCertificateUrl: [],
    });

    const selectedFileType = ref(null);

    const handleBeforeUpload = (type) => (file) => {
      selectedFileType.value = type;
      if (type === 'video') {
        // Video file restrictions
        const isMp4 = file.type === 'video/mp4';
        const isLt50M = file.size / 1024 / 1024 < 50;

        if (!isMp4) {
          message.error('只能上传 MP4 格式的视频');
          return false;
        }
        if (!isLt50M) {
          message.error('视频大小不能超过 50MB');
          return false;
        }
      } else {
        // Image file restrictions
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('只能上传 JPG, JPEG, 或 PNG 格式的文件');
          return false;
        }
      }
      return true;
    };


    const customUploadRequest = async ({ file }) => {
      let url;

      if (selectedFileType.value === 'video') {
        // 上传视频并获取 URL
        url = await uploadVideo(file);
        // 更新视频 fileList
        fileListVideo.value = [{ name: file.name, url, status: 'done' }];
        formState.demoUrl = url;
        selectedFileType.value = 'video'; // 设置文件类型为视频
      } else {
        // 上传图片并获取 URL
        url = await uploadFile(file, selectedFileType.value);
        const fileItem = { name: file.name, url, status: 'done' };
        // 根据类型更新对应的 fileList
        if (selectedFileType.value === 'license') {
          // 检查营业执照文件数量，避免覆盖第一张
          if (fileListLicense.value.length < 2) {
            fileListLicense.value = [...fileListLicense.value, fileItem];
            formState.mallCertificateUrl = [...formState.mallCertificateUrl, url];
            selectedFileType.value = 'image'; // 设置文件类型为图片
          }
        } else if (selectedFileType.value === 'previewCode') {
          fileListPreviewCode.value = [fileItem];
          formState.promotionCodeUrl = url;
          selectedFileType.value = 'image'; // 设置文件类型为图片
        }
      }
    };

    const handleRemove = (type, file) => {
      if (type === 'license') {
        fileListLicense.value = fileListLicense.value.filter(item => item.uid !== file.uid);
      } else if (type === 'video') {
        fileListVideo.value = fileListVideo.value.filter(item => item.uid !== file.uid);
      } else if (type === 'previewCode') {
        fileListPreviewCode.value = fileListPreviewCode.value.filter(item => item.uid !== file.uid);
      }
    };

    const handlePreview = (file) => {
      if (file && file.url) {
        previewUrl.value = file.url;
        previewVisible.value = true;
        previewTitle.value = file.name; // 设置文件名

        const extension = file.name.split('.').pop().toLowerCase();
        selectedFileType.value = extension === 'mp4' ? 'video' : 'image';
      } else {
        console.error("无法预览：文件没有 URL");
      }
    };


    //视频处理分片
    const uploadVideo = async (file) => {

      const partSize = 10 * 1024 * 1024; // 10MB 分片大小
      const totalParts = Math.ceil(file.size / partSize);

      // 第一步: 初始化上传以获取 upload_sign
      const uploadSign = await initializeUpload();

      // 第二步: 分片上传
      for (let i = 0; i < totalParts; i++) {
        const start = i * partSize;
        const end = Math.min(start + partSize, file.size);
        const partBlob = file.slice(start, end); // 创建文件分片

        // 调用 uploadPart 方法上传每个分片
        // console.log(`Uploading part: ${i + 1}, Size: ${partBlob.size}`);
        await uploadPart(partBlob, i + 1, uploadSign);
      }
      // 第三步: 获取最终视频URL
      const finalUrl = await getFinalUrl(uploadSign);
      // console.log('最终视频URL:', finalUrl);
      return finalUrl;
    };

    //分片接口初始化
    const initializeUpload = async () => {
      try {
        const response = await axios.post('/initialize_upload', {});
        // console.log("Response:", response.data);
        return response.data.upload_sign; // 假设返回的数据中包含 upload_sign
      } catch (error) {
        // console.error('初始化上传失败:', error);
      }
    };


    //分片接口上传
    const uploadPart = async (partBlob, partNum, uploadSign) => {
      try {
        const formData = new FormData();
        formData.append('part_file', partBlob);
        formData.append('part_num', partNum);
        formData.append('upload_sign', uploadSign);

        // 发送 POST 请求上传分片
        const response = await axios.post('/upload-part', formData);
        // console.log(`Part ${partNum} uploaded, response:`, response.data);
        return response.data; // Promise 解决
      } catch (error) {
        // console.error('上传分片失败:', error);
        throw error; // Promise 拒绝
      }
    };

    //分片接口上传完成，获取url
    const getFinalUrl = async (uploadSign) => {
      try {
        const response = await axios.post('/get-final-url', { upload_sign: uploadSign });
        return response.data.final_url; // 假设返回的数据中包含最终 URL
      } catch (error) {
        console.error('获取视频失败:', error);
      }
    };

    const uploadFile = async (file, type) => {

      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', type);  // 'video', 'license', 'previewCode'

      try {
        const response = await axios.post('/upload-file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        // console.log(`File uploaded, response:`, response.data.url);
        // 这里可以处理响应，比如更新状态或显示成功消息
        return response.data.url; // 返回响应数据
      } catch (error) {
        console.error('File upload failed:', error);
        // 这里可以处理错误，比如显示错误消息
      }
    };

    const open = ref(false);
    const confirmLoading = ref(false);
    const showModal = () => {
      open.value = true;
    };


    const columns = ref([
      {
        title: '店铺ID',
        dataIndex: 'mall_id',
        key: 'mall_id',
        resizable: true,
      },
      {
        title: '商品ID',
        dataIndex: 'goods_id',
        key: 'goods_id',
        resizable: true,
        width: 200,
      },
      {
        title: '报备提交时间',
        dataIndex: 'commit_time',
        key: 'commit_time',
        resizable: true,
        customRender: ({ text }) => {
          return moment(text).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '报备状态',//0-已创建，1-已提交，2-已通过，3-已驳回
        dataIndex: 'status',
        key: 'status',
        resizable: true,
        customRender: ({ text }) => {
          let statusLabel = '';
          switch (text) {
            case 0:
              statusLabel = '已创建';
              break;
            case 1:
              statusLabel = '已提交';
              break;
            case 2:
              statusLabel = '已通过';
              break;
            case 3:
              statusLabel = '已驳回';
              break;
            default:
              statusLabel = '未知';
          }
          return statusLabel;
        },

      },
      {
        title: '推广开始时间',
        dataIndex: 'promotion_start_time',
        key: 'promotion_start_time',
        resizable: true,
        customRender: ({ text }) => {
          return moment(text).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        title: '推广结束时间',
        dataIndex: 'promotion_end_time',
        key: 'promotion_end_time',
        resizable: true,
        customRender: ({ text }) => {
          return moment(text).format('YYYY-MM-DD HH:mm:ss');
        },
      },

    ]);

    const dates = ref();
    const value = ref();
    const hackValue = ref();
    const disabledDate = current => {
      // 获取今天的日期（不包含时间）
      const today = moment().startOf('day');

      // 检查当前日期是否在今天之前
      if (current < today) {
        return true;
      }

      if (!dates.value || dates.value.length === 0) {
        return false;
      }

      const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') >= 90;
      const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') >= 90;

      return tooEarly || tooLate;
    };
    const onOpenChange = open => {
      if (open) {
        dates.value = [];
        hackValue.value = [];
      } else {
        hackValue.value = undefined;
      }
    };
    const onChange = (dates) => {
      if (dates && dates.length === 2) {
        formState.promotionStartDate = dates[0].startOf('day').valueOf(); // 或者使用格式化的日期字符串
        formState.promotionEndDate = dates[1].endOf('day').valueOf(); // 或者使用格式化的日期字符串
      }
    };


    const submitFormToFlask = async () => {

      // 构造请求数据
      const requestData = {
        demoUrl: fileListVideo.value.length > 0 ? fileListVideo.value[0].url : '',
        goodsId: formState.goods_id,
        mallCertificateUrl: fileListLicense.value.map(file => file.url),
        promotionCodeUrl: fileListPreviewCode.value.length > 0 ? fileListPreviewCode.value[0].url : '',
        promotionStartDate: formState.promotionStartDate,
        promotionEndDate: formState.promotionEndDate,
      };
      // console.log(requestData);
      // 发送请求到 Flask
      try {
        const response = await axios.post('/promotion', requestData);
        const resData = response.data.goods_promotion_right_auth_response;

        if (resData && resData.result) {
          // 请求成功
          message.success(resData.reason || '报备成功');
          handleCancel();
          open.value = false;
          confirmLoading.value = false;
        } else {
          // 请求失败
          message.error(resData.reason || '报备失败');
          confirmLoading.value = false;
        }
      } catch (error) {
        // console.error('Error submitting form:', error);
        message.error('报备过程中出现错误');
        confirmLoading.value = false;
      }
    };

    const handleCancel = () => {
      open.value = false;
      formState.goods_id = '';
      formState.promotionStartDate = null;
      formState.promotionEndDate = null;
      formState.demoUrl = '';
      formState.mallCertificateUrl = '';
      formState.promotionCodeUrl = '';

      // 清空上传组件的文件列表
      fileListVideo.value = [];
      fileListLicense.value = [];
      fileListPreviewCode.value = [];

      // 清空时间选择器的值
      // 注意：这里假设 rangePickerValue 是响应式引用
      rangePickerValue.value = [];
    };



    const handleOk = async () => {
      confirmLoading.value = true;
      await submitFormToFlask();
    };


    const onCalendarChange = val => {
      dates.value = val;
    };

    const labelCol = {
      style: {
        width: '100px',
      },
    };
    const wrapperCol = {
      span: 14,
    };

    const getRules = (field) => {
  switch (field) {
    case 'goods_id':
      return [{ required: true, message: '请输入商品ID' }];
    case 'promotion_time':
      return [{ required: true, message: '请选择推广时间段' }];
    case 'license':
      return [{ required: true, message: '请上传营业执照' }];
    case 'video':
      return [{ required: true, message: '请上传视频素材' }];
    case 'previewCode':
      return [{ required: true, message: '请上传预览码' }];
    // Add more cases for other fields if needed
    default:
      return [];
  }
};

    const onSubmit = async () => {
      // 验证 dataID 是否为空
      if (!formState.dataID) {
        message.error('请输入查询数据！');
        return;
      }

      // 验证 dataID 是否为数字
      if (!/^\d+$/.test(formState.dataID)) {
        message.error('数据只能包含数字');
        return;
      }
      isLoading.value = true;
      searchResults.value = [];
      const API_URL = '/promotion_progress';
      try {
        const response = await axios.post(API_URL, {
          dataID: formState.dataID,
          option: tradetext.value,
        });
        // 检查响应数据是否为空数组或空数据
        if (response.data && response.data.response && response.data.response.application_list && response.data.response.application_list.length > 0) {
          // 如果有数据
          searchResults.value = response.data.response.application_list;
        } else {
          // 如果是空数组或空数据
          message.error('暂无数据');
        }
      } catch (error) {
        console.error(error);
        message.error('数据加载失败');
      }
      isLoading.value = false;
    };



    return {
      tradetext,
      formState,
      searchResults,
      columns,
      isLoading,
      handleResizeColumn,
      showModal,
      handleOk,
      open,
      confirmLoading,
      dates,
      value,
      hackValue,
      disabledDate,
      onOpenChange,
      onChange,
      onCalendarChange,
      locale,
      dayjs,
      labelCol,
      wrapperCol,
      initializeUpload,
      handleBeforeUpload,
      customUploadRequest,
      uploadPart,
      getFinalUrl,
      uploadFile,
      selectedFileType,
      submitFormToFlask,
      fileListVideo,
      fileListLicense,
      fileListPreviewCode,
      loading,
      handleRemove,
      handlePreview,
      previewVisible,
      previewUrl,
      previewTitle,
      handleCancel,
      rangePickerValue,
      onSubmit,
    }
  }

})

function handleResizeColumn(w, col) {
  col.width = w;
}
</script>
<style>
.table-container {
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;
}

.ant-table-thead>tr>th {
  white-space: nowrap;
  /* 阻止文字换行 */
  overflow: hidden;
  /* 隐藏溢出的文字 */
  text-overflow: ellipsis;
  /* 使用省略号表示溢出 */
  max-width: 150px;
  /* 根据需要调整最大宽度 */
}

.ant-table-tbody>tr>td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  /* 根据需要调整最大宽度 */
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>

 