<template>
  <a-row>
    <a-col :span="24">
      <a-form ref="formRef" :model="formState" :rules="formState.rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <!-- 平台选择 -->
        <a-form-item label="平台">
          <a-radio-group v-model:value="platformValue" name="platform">
            <a-radio value="pdd">拼多多</a-radio>
            <a-radio value="jd">京东</a-radio>
          </a-radio-group>
        </a-form-item>


        <!-- 店铺名称和商品ID -->
        <a-row>
          <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <a-form-item name="keyword" v-if="platformValue === 'pdd'">
              <template v-slot:label>
                <span>商品ID</span>
                <a-tooltip title="输入数字ID,点击检索获取转链商品ID">
                  <QuestionCircleOutlined style="margin-left: 8px;" />
                </a-tooltip>
              </template>
              <a-input-group compact>
                <a-input v-model:value="formState.keyword" style="width: calc(100% - 100px)" />
                <a-button type="primary" @click="search">检索</a-button>
              </a-input-group>
            </a-form-item>

          </a-col>
        </a-row>
        <a-row>
          <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <a-form-item label="商品ID" name="itemid" v-if="platformValue === 'jd'">
              <a-input v-model:value="formState.itemid" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-form-item>
          <a-button type="primary" :loading="isLoading" @click="onSubmit">点击转链</a-button>
        </a-form-item>
      </a-form>

      <a-col :span="24">
        <div class="table-container">
          <a-table :columns="columns" :dataSource="tableData" @resizeColumn="handleResizeColumn" :pagination="false">
            <template v-slot:operation="{ record }">
              <a-space>
                <a @click="copyResultsToClipboard(record)">复制</a>
                <a @click="exportToXlsx(record)">导出</a>
              </a-space>
            </template>
          </a-table>
        </div>
      </a-col>
    </a-col>
  </a-row>
</template>

<script>
import { ref, reactive, defineComponent } from 'vue';
import { message } from 'ant-design-vue';
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import axios from 'axios';
import * as XLSX from 'xlsx';


export default defineComponent({
  name: 'MiniApp',
  components: {
    QuestionCircleOutlined,
  },
  setup() {
    const results = ref([]);
    const platformValue = ref('pdd');
    const formRef = ref(null);
    const isLoading = ref(false);
    const Loading = ref(false);
    const tableData = ref([]);
    const old_keyword = ref('');


    const formState = reactive({
      itemid: '',
      keyword: '',
      page_path: '',
      source_display_name: '',
      title: '',
      user_name: '',
      rules: {
        keyword: [{ required: true, message: '数字商品ID是必填项', trigger: 'blur' }],
        itemid: [{ required: true, message: '商品ID是必填项', trigger: 'blur' }],
      },
    });

    const columns = ref([
      {
        title: '小程序名称',
        dataIndex: 'source_display_name',
        key: 'source_display_name',
        resizable: true,
        width: 120,
      },
      {
        title: '商品标题',
        dataIndex: 'title',
        key: 'title',
        resizable: true,
        width: 200,
      },
      {
        title: '小程序原始ID',
        dataIndex: 'user_name',
        key: 'user_name',
        resizable: true,
        width: 144,
      },
      {
        title: '小程序链接',
        dataIndex: 'page_path',
        key: 'page_path',
        width: 500,
        resizable: true,
      },
      {
        title: '操作',
        key: 'operation',
        dataIndex: 'operation',
        width: 100,
        fixed: 'right',
        slots: { customRender: 'operation' },
      },

    ]);



    // 查询商品id
    const search = async () => {
      Loading.value = true;
      old_keyword.value = formState.keyword; // 保存原始 keyword 值
      try {
        const response = await axios.post('/goods_search', {
          keyword: formState.keyword,
        });
        Loading.value = false;
        if (response.data && response.data.length > 0) {
          const goodsSign = response.data[0].goods_sign; // 提取 goods_sign
          formState.keyword = goodsSign; // 更新 goodsSign
        } else {
          message.error('未找到商品信息');
        }
      } catch (error) {
        console.error('查询失败:', error);
        message.error('查询失败');
        Loading.value = false;
      }
    };

    // 转链
    const onSubmit = async () => {
      isLoading.value = true;
      tableData.value = [];

      try {
        let postData = {};

        if (platformValue.value === 'pdd') {
          // 拼多多的请求数据
          postData = {
            goods_sign_list: formState.keyword,
            custom_parameters: '{"did":"__CLICK_ID__:__CLICK_TIME__:__CAMPAIGN_ID__:__ADGROUP_ID__:__AD_ID__:__ACCOUNT_ID__:__ADGROUP_NAME__:__CALLBACK__","uid":"11111"}',
            platform: 'pdd'
          };
        } else if (platformValue.value === 'jd') {
          // 京东的请求数据
          postData = {
            // 构建针对京东的请求数据
            materialId: formState.itemid,
            ext1: '^__CLICK_ID__^__ADGROUP_ID__^__ADVERTISER_ID__',
            platform: 'jd'
          };
        }

        // 发送请求
        const response = await axios.post('/generate', postData);
        const data = response.data;
        tableData.value = Array.isArray(data) ? data : [data];

      } catch (error) {
        console.error('查询失败:', error);
        message.error('查询失败');
        isLoading.value = false;
      }
      isLoading.value = false;
    };


    // 复制
    const copyResultsToClipboard = (record) => {
      const productId = platformValue.value === 'pdd' ? old_keyword.value : formState.itemid;
      const textToCopy = `商品ID：${productId}\n小程序原始ID：${record.user_name}\n小程序链接：${record.page_path}`;
      navigator.clipboard.writeText(textToCopy).then(() => {
        message.success('复制成功');
      }).catch(err => {
        message.error('复制失败:', err);
      });
    };

    // 导出
    const exportToXlsx = (record) => {
      const productId = platformValue.value === 'pdd' ? old_keyword.value : formState.itemid;
      const exportData = [{
        "商品ID": productId,
        "小程序原始ID": record.user_name,
        "小程序链接": record.page_path
      }];

      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Results');
      XLSX.writeFile(wb, 'results.xlsx');
    };

    return {
      platformValue,
      formState,
      isLoading,
      Loading,
      results,
      formRef,
      exportToXlsx,
      copyResultsToClipboard,
      search,
      onSubmit,
      columns,
      handleResizeColumn,
      tableData,
    };
  }
})
function handleResizeColumn(w, col) {
  col.width = w;
}

</script>
<style>
.api-result {
  margin: 10px 0;
  width: 100%;
}

.alert {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.margin {
  margin: 0 5px;
}
</style>