<template>
  <a-row>
    <a-col :span="24">
      <a-input-group compact  :style="inputGroupStyle">
        <a-input v-model:value="formState.nick"  />
        <a-button type="primary" @click="onSubmit">搜索</a-button>
      </a-input-group>
    </a-col>
    <a-col :span="24">
      <div style="margin: 20px 0;" v-if="showTimeline">
        <a-timeline :pending="pending" :reverse="reverse">
          <a-timeline-item v-for="(item, name) in displayedServices" :key="name" :color="getItemColor(item)">
            <h3>{{ item.title }}</h3>
            <template v-if="item.authorized">
              <h4>订购时间：<span>{{ item.start_date }}</span></h4>
              <h4>到期时间：<span>{{ item.end_date }}</span></h4>
            </template>
            <template v-else>
              <h4>未授权/未购买</h4>
            </template>
          </a-timeline-item>
        </a-timeline>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { reactive, ref, computed  } from 'vue';
import axios from 'axios';

export default {
  name: 'ExpireDate',
  setup() {
    const formState = reactive({
      nick: "",
    });
    const displayedServices = reactive({});
    const showTimeline = ref(false);
    const pending = ref("Recording...");
    const reverse = ref(true);
    const getItemColor = (item) => {
      // 如果已授权且有有效的日期，则显示绿色，否则显示红色
      return item.authorized && item.start_date && item.end_date ? 'green' : 'red';
    };

    const services = reactive({
      miniapp: { title: '自动补库存', authorized: false, start_date: '', end_date: '' },
      miniapp1: { title: '商品视频王', authorized: false, start_date: '', end_date: '' },
      miniapp2: { title: '流量推广强', authorized: false, start_date: '', end_date: '' },
      miniapp4: { title: '商家云推', authorized: false, start_date: '', end_date: '' },
    });


    const onSubmit = async () => {
      try {
        const response = await axios.post('/expiredate', {
           nick: formState.nick 
          });
        updateServices(response.data);
      } catch (error) {
        console.error('请求出错：', error);
      }
    };

    const inputGroupStyle = computed(() => {
      // 使用 JavaScript 媒体查询判断屏幕宽度
      const isMobile = window.innerWidth <= 768;
      return {
        width: isMobile ? '100%' : '20%',
        display: 'flex', // 确保元素在同一行
        flexWrap: 'nowrap', // 防止换行
      };
    });

    function updateServices(data) {
      showTimeline.value = true;
      let index = 0;
      const serviceKeys = Object.keys(services);

      function displayNextItem() {
        const key = serviceKeys[index];
        if (key) {
          const value = data[key];
          displayedServices[key] = {
            ...services[key],
            authorized: value !== '未授权/未购买',
            start_date: value.start_date || '',
            end_date: value.end_date || ''
          };
          index++;
          if (index < serviceKeys.length) {
            setTimeout(displayNextItem, 1000);
          } else {
            pending.value = false; // 移除 pending 属性
          }
        }
      }

      displayNextItem();
    }

    return { formState, onSubmit, displayedServices, showTimeline, pending, reverse, getItemColor, inputGroupStyle };
  }
}
</script>