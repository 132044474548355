<template>
  <a-row>
 <a-col :span="20" :offset="2">
   <a-tabs>
     <a-tab-pane key="1" tab="店铺数据">
       <Shopdata />
     </a-tab-pane>
     <a-tab-pane key="2" tab="商品数据" >
       <Itemdata />
     </a-tab-pane>
   </a-tabs>
 </a-col>
</a-row>
</template>

<script>
import Itemdata from './collect/Itemdata.vue'
import Shopdata from './collect/Shopdata.vue'


export default {
  components: {
    Itemdata,
    Shopdata,
  },
  data() {
    return {
      isAuthenticated: false
    }
  },
  methods: {
    handleAuthSuccess() {
      this.isAuthenticated = true;
    }
  }
}
</script>


<style scoped>
h3 {
  text-align: center;
}

.center {
  text-align: center;
}
</style>
