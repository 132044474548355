<template>
  <a-row>
    <a-col :span="24">
      <a-form ref="formRef" :model="formState" :rules="formState.rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <!-- 平台选择 -->
        <a-form-item label="平台">
          <a-radio-group v-model:value="platformValue" name="platform">
            <a-radio value="toutiao">巨量引擎</a-radio>
            <a-radio value="kuaishou" disabled>磁力引擎</a-radio>
            <a-radio value="tencent" disabled>腾讯广告</a-radio>
          </a-radio-group>
        </a-form-item>

        <!-- 链路选择 -->
        <a-form-item label="链路">
          <a-radio-group v-model:value="linkValue" name="link">
            <a-radio value="details">商品详情页</a-radio>
            <a-radio value="taojimu">淘积木</a-radio>
            <a-radio value="taobaoLive">淘宝直播</a-radio>
          </a-radio-group>
        </a-form-item>

        <!-- 店铺名称和商品ID -->
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="店铺名称" name="nick">
              <a-input v-model:value="formState.nick" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="商品ID" name="itemid">
              <a-input v-model:value="formState.itemid" />
            </a-form-item>
          </a-col>
        </a-row>

        <!-- 根据链路选择显示的表单项 -->
        <a-form-item v-if="linkValue == 'taojimu'" label="淘积木链接" name="tjmurl">
          <a-input v-model:value="formState.tjmurl"
            placeholder="链接不能过于长。格式：https://xxxxxxx/xxxxxxxxxxx&shop_id=xxxxxxxx" />
        </a-form-item>
        <a-form-item v-if="linkValue == 'taobaoLive'" label="直播间ID" name="roomid">
          <a-input v-model:value="formState.roomid" />
        </a-form-item>

        <!-- 小程序选择 -->
        <a-form-item label="小程序：">
          <a-radio-group v-model:value="miniAppValue" name="miniapp">
            <a-radio value="videoKing">商品视频王</a-radio>
            <a-radio value="trafficStrong">流量推广强</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-row :gutter="16">
          <a-col :span="16">
            <!-- 根据小程序选择显示模板 -->
            <a-form-item v-if="miniAppValue === 'videoKing'" label="商品视频王模板" name="tempid">
              <a-select v-model:value="formState.tempid" placeholder="选择模板">
                <!-- 商品视频王的模板选项 -->
                <a-select-option value="3000000079474002">3000000079474002</a-select-option>
                <a-select-option value="3000000081440321">3000000081440321</a-select-option>
                <a-select-option value="3000000082543081">3000000082543081</a-select-option>
                <a-select-option value="3000000086569845">3000000086569845</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-if="miniAppValue === 'trafficStrong'" label="流量推广强模板" name="tempid">
              <a-select v-model:value="formState.tempid" placeholder="选择模板">
                <!-- 流量推广强的模板选项 -->
                <a-select-option value="3000000079495521">3000000079495521</a-select-option>
                <a-select-option value="3000000084712912">3000000084712912</a-select-option>
                <a-select-option value="3000000086378621">3000000086378621</a-select-option>
                <a-select-option value="3000000090218561">3000000090218561</a-select-option>
                <a-select-option value="3000000095575353">3000000095575353</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <!-- 转链条数和提交按钮 -->
            <a-form-item label="转链条数">
              <a-input-number v-model:value="formState.requestCount" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item>
          <a-button type="primary" :loading="isLoading" @click="onSubmit">点击转链</a-button>
        </a-form-item>
      </a-form>
      <!-- 显示复制按钮 -->
      <a-button type="primary" v-if="!isLoading && results.length > 0" @click="copyResultsToClipboard">
        复制结果
      </a-button>

      <a-button type="primary" class="margin" v-if="!isLoading && results.length > 0" @click="exportToXlsx" :size="size">
        <template #icon>
          <DownloadOutlined />
        </template>
        导出结果
      </a-button>
      <!-- 循环显示结果 -->
      <div class="alert">
        <div v-for="(result, index) in results" :key="index" class="api-result">
          <a-alert :message="result" type="info" />
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { ref, reactive, watch, computed } from 'vue';
import { message } from 'ant-design-vue';
import { DownloadOutlined, InfoCircleFilled } from '@ant-design/icons-vue';
import axios from 'axios';
import * as XLSX from 'xlsx';


export default {
  name: 'MiniApp',
  setup() {
    const results = ref([]);
    const platformValue = ref('toutiao');
    const linkValue = ref('details');
    const miniAppValue = ref('videoKing');
    const formRef = ref(null);

    const formState = reactive({
      nick: '',
      itemid: '',
      tjmurl: '',
      roomid: '',
      tempid: '3000000079474002',
      requestCount: '1',
      rules: {
        nick: [{ required: true, message: '店铺名称是必填项', trigger: 'blur' }],
        itemid: [{ required: true, message: '商品ID是必填项', trigger: 'blur' }],
        tjmurl: [],
        roomid: [],
      },
    });

    watch(linkValue, (newVal) => {
      if (newVal === 'taojimu') {
        formState.rules.tjmurl = [{ required: true, message: '淘积木链接是必填项', trigger: 'blur' }];
        formState.rules.roomid = [];
      } else if (newVal === 'taobaoLive') {
        formState.rules.roomid = [{ required: true, message: '直播间ID是必填项', trigger: 'blur' }];
        formState.rules.tjmurl = [];
      } else {
        formState.rules.tjmurl = [];
        formState.rules.roomid = [];
      }
    });



    // 模板 和 version 的映射关系
    const tempidToVersion = {
      "3000000079474002": "0.0.30",
      "3000000081440321": "0.0.10",
      "3000000082543081": "0.0.9",
      "3000000086569845": "0.0.6",
      "3000000079495521": "0.0.4",
      "3000000084712912": "0.0.3",
      "3000000086378621": "0.0.5",
      "3000000090218561": "0.0.2",
      "3000000095575353": "0.0.2",
    };

    // 根据 tempid 获取 version
    const version = computed(() => tempidToVersion[formState.tempid] || '');

    // 监听链路选择
    watch(linkValue, (newValue) => {
      if (newValue === 'taojimu') {
        formState.roomid = ''; // 清空直播间ID
      } else if (newValue === 'taobaoLive') {
        formState.tjmurl = ''; // 清空淘积木链接
      }
    });

    // 平台选择绑定value
    const platformParams = computed(() => {

      switch (platformValue.value) {
        case "toutiao":
          return "{type:xjl,dab:__PROJECT_ID__,dcd:__PROMOTION_ID__,def:__REQUEST_ID__,dgh:__CLICKID__SWHWL,dij:__ADVERTISER_ID__,mid1:__MID1__,mid2:__MID2__,mid3:__MID3__,mid5:__MID5__,csite:__CSITE__,convertId:__CONVERT_ID__,trackId:__TRACK_ID__,sl:__SL__,imei:__IMEI__,idfa:__IDFA__,idfaMd5:__IDFA_MD5__,androidid:__ANDROIDID__,oaid:__OAID__,oaidMd5:__OAID_MD5__,os:__OS__,ip:__IP__,ts:__TS__,model:__MODEL__,ipv4:__IPV4__,ipv6:__IPV6__}";
        case "kuaishou":
          return "{type:ggg,gab:__CLICK_ID__,gcd:__CLICK_TIME__,gef:__CAMPAIGN_ID__,ggh:__ADGROUP_ID__,gij:__AD_ID__,gkl:__ACCOUNT_ID__,gmn:__ADGROUP_NAME__,gop:__CALLBACK__,gre: __REQUEST_ID__}";
        case "tencent":
          return "{type:ggg,gab:__CLICK_ID__,gcd:__CLICK_TIME__,gef:__CAMPAIGN_ID__,ggh:__ADGROUP_ID__,gij:__AD_ID__,gkl:__ACCOUNT_ID__,gmn:__ADGROUP_NAME__,gop:__CALLBACK__,gre:__REQUEST_ID__}";
      }

    })

    // 动态更新formState.tempid
    watch(miniAppValue, (newValue) => {
      if (newValue === 'videoKing') {
        formState.tempid = '3000000079474002';
      } else if (newValue === 'trafficStrong') {
        formState.tempid = '3000000079495521';
      }
    });

    const isLoading = ref(false);


    const onSubmit = async () => {
      isLoading.value = true;

      try {
        // 使用 Promise 方式进行表单验证
        await formRef.value.validate();
        results.value = []; // 清空之前的结果
        const API_URL = '/api';
        // 验证通过后的逻辑
        for (let i = 0; i < formState.requestCount; i++) {
          try {
            const response = await axios.post(API_URL, {
              itemid: formState.itemid,
              nick: formState.nick,
              tjmurl: formState.tjmurl,
              roomid: formState.roomid,
              tempid: formState.tempid,
              version: version.value,
              platform: platformValue.value,
              link: linkValue.value,
              miniapp: miniAppValue.value,
              platformParams: platformParams.value,
            });
            results.value.push(response.data);
          } catch (error) {
            console.error('请求错误:', error);
            isLoading.value = false;
            return; // 如果出现错误，退出循环
          }
        }
        message.success('链接成功'); // 所有请求完成后显示成功信息
      } catch (error) {
        // 验证失败的逻辑
        console.error('验证失败:', error);
      }

      isLoading.value = false;
    };



    const copyResultsToClipboard = () => {
      const textToCopy = results.value.join('\n--------------------\n');
      navigator.clipboard.writeText(textToCopy).then(() => {
        message.success('复制成功');
      }).catch(err => {
        message.error('复制失败:', err);
      });
    };

    const exportToXlsx = () => {
      const ws = XLSX.utils.json_to_sheet(results.value.map(result => ({ '直达链接': result })));
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Results');
      XLSX.writeFile(wb, 'results.xlsx');
    };

    return {
      platformValue,
      linkValue,
      miniAppValue,
      formState,
      platformParams,
      version,
      isLoading,
      results,
      formRef,
      exportToXlsx,
      onSubmit,
      copyResultsToClipboard,
    };
  }
}
</script>
<style>
.api-result {
  margin: 10px 0;
  width: 100%;
}

.alert {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.margin {
  margin: 0 5px;
}
</style>