<template>
  <div>
    <pdd-auth v-if="!isAuthenticated" @auth-success="handleAuthSuccess"></pdd-auth>
    <pdd-promotion v-if="isAuthenticated"></pdd-promotion>
  </div>
</template>

<script>
import PddAuth from './PddAuth.vue'
import PddPromotion from './PddPromotion.vue'


export default {
  components: {
    PddAuth,
    PddPromotion,
  },
  data() {
    return {
      isAuthenticated: false
    }
  },
  methods: {
    handleAuthSuccess() {
      this.isAuthenticated = true;
    }
  }
}
</script>


<style scoped>
h3 {
  text-align: center;
}

.center {
  text-align: center;
}
</style>
