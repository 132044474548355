<template>
  <a-row>
    <a-col :span="20">
      <a-form
    :model="formState"
    name="basic"
    autocomplete="off"
    @finish="onFinish"
    @finishFailed="onFinishFailed"
    @submit.prevent="authenticateUser"
  >
    <a-form-item
      label="账号"
      name="username"
      :rules="[{ required: true, message: '请输入账号！' }]"
    >
      <a-input v-model:value="formState.username" />
    </a-form-item>

    <a-form-item
      label="密码"
      name="password"
      :rules="[{ required: true, message: '请输入密码！' }]"
    >
      <a-input-password v-model:value="formState.password" />
    </a-form-item>

    <a-form-item >
      <a-button type="primary" html-type="submit">验证</a-button>
    </a-form-item>
  </a-form>
    </a-col>
  </a-row>
</template>

<script>
import { reactive, ref, computed  } from 'vue';
import axios from 'axios';
import { message } from 'ant-design-vue';

export default {
  name: 'PddPromotion',
  name: 'ChannelData',
  setup(_, context) {
    const formState = reactive({
    username: '',
    password: '',
    remember: true,
});
const onFinish = values => {
  console.log('Success:', values);
};
const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};
const authenticateUser = async () => {
      try {
        const response = await axios.post('/pddauth', {
          username: formState.username,
          password: formState.password
        });

        if (response.data.success) {
          // 触发某个事件或进行路由跳转
          context.emit('auth-success');
        } else {
          message.error('用户名或密码错误');
        }
      } catch (error) {
        console.error('验证失败:', error);
        message.error('验证过程中出现错误');
      }
    };


    return { formState,onFinish,onFinishFailed,authenticateUser };
  }
  
}
</script>