<template>
  <a-row>
    <a-col :span="24">
      <a-input-group compact>
        <a-select v-model:value="tradetext">
          <a-select-option value="Option1">子订单号</a-select-option>
          <a-select-option value="Option2">父订单号</a-select-option>
        </a-select>
        <a-input v-model:value="formState.tradeID" style="width: 50%" />
        <a-button type="primary" @click="onSubmit" :loading="isLoading">搜索</a-button>
      </a-input-group>
    </a-col>
    <a-col :span="24">
      <div class="table-container">
        <a-table :columns="columns" :dataSource="searchResults" @resizeColumn="handleResizeColumn" :pagination="true"
          row-key="tradeId"></a-table>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { ref, reactive, h } from 'vue';
import { message } from 'ant-design-vue';
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'Llt',
  setup() {
    const tradetext = ref('Option1');
    const isLoading = ref(false);
    const searchResults = ref([]);
    const formState = reactive({
      tradeID: '',
    });

    const formatDate = (timestamp) => {
      const date = new Date(timestamp * 1000);
      const offset = date.getTimezoneOffset() * 60000; // 时区偏移量（毫秒）
      const localDate = new Date(date.getTime() - offset);
      return localDate.toISOString().slice(0, 19).replace('T', ' ');
    };


    const formatCommissionToPercentage = (commission) => {
      return `${(commission * 0.01).toFixed(2)}%`;
    };


    const columns = ref([
      {
        title: '订单号',
        dataIndex: 'tradeId',
        key: 'tradeId',
        resizable: true,
      },
      {
        title: '店铺名称',
        dataIndex: 'sellerNick',
        key: 'sellerNick',
        resizable: true,
        width: 200,
      },
      {
        title: '商品ID',
        dataIndex: 'itemId',
        key: 'itemId',
        resizable: true,
      },
      {
        title: '类目',
        dataIndex: 'itemCategoryName',
        key: 'itemCategoryName',
        resizable: true,

      },
      {
        title: '佣金',
        dataIndex: 'trafficTechServiceRate',
        key: 'trafficTechServiceRate',
        customRender: ({ text }) => {
          const commission = parseFloat(text);
          if (commission === 0) {
            return h('span', { style: 'color: red;' }, '不达标');
          } else {
            return formatCommissionToPercentage(commission);
          }
        },
        resizable: true,
      },
      {
        title: '付款时间',
        dataIndex: 'tbPaidTime',
        key: 'tbPaidTime',
        customRender: ({ text }) => formatDate(text),
        resizable: true,
      },
    ]);

    const onSubmit = async () => {
      isLoading.value = true;
      searchResults.value = [];
      const API_URL = '/search';
      try {
        const response = await axios.post(API_URL, {
          trade_id: formState.tradeID,
          option: tradetext.value,
        });
        // 检查响应数据是否为空数组或空数据
        if (response.data === null || response.data.length === 0) {
          // 如果是空数组或空数据
          message.error('暂无数据');
        } else {
          // 如果有数据
          searchResults.value = Array.isArray(response.data) ? response.data : [response.data];
        }
      } catch (error) {
        console.error(error);
      }
      isLoading.value = false;
    };




    return {
      tradetext,
      formState,
      searchResults,
      columns,
      isLoading,
      handleResizeColumn,
      onSubmit,
    };
  }
}
function handleResizeColumn(w, col) {
  col.width = w;
}
</script>
<style>
.table-container {
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;
}

.ant-table-thead>tr>th {
  white-space: nowrap;
  /* 阻止文字换行 */
  overflow: hidden;
  /* 隐藏溢出的文字 */
  text-overflow: ellipsis;
  /* 使用省略号表示溢出 */
  max-width: 150px;
  /* 根据需要调整最大宽度 */
}

.ant-table-tbody>tr>td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  /* 根据需要调整最大宽度 */
}
</style>
