<template>
  <div>
    <a-date-picker v-model:value="selectedDate" placeholder="日期" :locale="locale"
      style="margin-right: 16px;"></a-date-picker>
    <a-input v-model:value="selectedshopNames" placeholder="请输入店铺名称，可查询多个店铺名称，格式：店铺名称1,店铺名称2，中间用英文字符的逗号隔开"
      style="width: 60%;"></a-input>
    <a-button type="primary" @click="fetchData" style="margin-left: 16px;">查询</a-button>

    <a-table :columns="columns" :data-source="tableData" :loading="loading"></a-table>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Table, DatePicker, Input, Button } from 'ant-design-vue';
import axios from 'axios';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

export default {
  components: {
    ATable: Table,
    ADatePicker: DatePicker,
    AInput: Input,
    AButton: Button,
  },
  setup() {
    const tableData = ref([]);
    const loading = ref(false);
    const selectedDate = ref(null);
    const selectedshopNames = ref('');

    const columns = [
      {
        title: '日期',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: '店铺名称',
        dataIndex: 'shop_name',
        key: 'shop_name',
      },
      {
        title: '点击次数',
        dataIndex: 'click_pv',
        key: 'click_pv',
      },
      {
        title: '点击人数',
        dataIndex: 'click_uv',
        key: 'click_uv',
      },
      {
        title: '收藏人数',
        dataIndex: 'favor_uv',
        key: 'favor_uv',
      },
      {
        title: '加购人数',
        dataIndex: 'cart_uv',
        key: 'cart_uv',
      },
      {
        title: '付款笔数',
        dataIndex: 'pay_pv',
        key: 'pay_pv',
      },
      {
        title: '付款金额（单位：元）',
        dataIndex: 'pay_price',
        key: 'pay_price',
      },
    ];

    const fetchData = async () => {
      loading.value = true;
      try {
        const date = selectedDate.value ? dayjs(selectedDate.value).format('YYYY-MM-DD') : '';
        const response = await axios.post('/getshopdata', {
          shop_names: selectedshopNames.value,
          date: date,
        });
        tableData.value = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      loading.value = false;
    };

    return {
      tableData,
      loading,
      columns,
      selectedDate,
      selectedshopNames,
      fetchData,
      dayjs,
      locale
    };
  },
};
</script>
