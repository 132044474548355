<template>
  <a-typography-title :level="3">自立更生页</a-typography-title>
  <div id="ww_1662047f5007d" v='1.3' loc='auto' class="center"
    a='{"t":"responsive","lang":"zh","sl_lpl":1,"ids":[],"font":"Arial","sl_ics":"one_a","sl_sot":"celsius","cl_bkg":"image","cl_font":"#FFFFFF","cl_cloud":"#FFFFFF","cl_persp":"#81D4FA","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722","cl_odd":"#0000000a","sl_tof":"5"}'>
    <a href="https://weatherwidget.org/zh/" id="ww_1662047f5007d_u" target="_blank">天气插件</a>
  </div>
  <a-row>
    <a-col :span="20" :offset="2">
      <a-tabs>
        <a-tab-pane key="1" tab="小程序转链">
          <MiniApp />
        </a-tab-pane>
        <a-tab-pane key="2" tab="小程序到期时间" >
          <ExpireDate />
        </a-tab-pane>
        <a-tab-pane key="3" tab="流量通订单查询">
          <Llt />
        </a-tab-pane>
        <a-tab-pane key="4" tab="腾讯广告小程序转链接">
          <Qq_ads />
        </a-tab-pane>
        <a-tab-pane key="5" tab="拼多多备案">
          <Pdd />
        </a-tab-pane>
        <!-- <a-tab-pane key="6" tab="测试">
          <Data />
        </a-tab-pane> -->
        <a-tab-pane key="6" tab="收藏加购">
          <Collect />
        </a-tab-pane>
        
        <!-- <a-tab-pane key="7" tab="收藏加购">
          <Itemdata />
        </a-tab-pane> -->
      </a-tabs>
      
    </a-col>
  </a-row>
</template>

<script>
import MiniApp from './MiniApp.vue'
import Llt from './Llt.vue'
import ExpireDate from './ExpireDate.vue'
import Pdd from './Pdd.vue'
import Qq_ads from './Qq_ads.vue'
// import Data from './Data.vue'
// import Itemdata from './Itemdata.vue'
import Collect from './Collect.vue'

export default {
  name: 'IndexPage',
  components: {
    MiniApp,
    Llt,
    ExpireDate,
    Pdd,
    Qq_ads,
    // Data,
    // Itemdata,
    Collect,
  },
}
</script>

<style scoped>
h3 {
  text-align: center;
}

.center {
  text-align: center;
}
</style>
